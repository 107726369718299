import {I$W, IWidgetController} from '@wix/native-components-infra/dist/es/src/types/types';
import {IControllerFactoryConfig} from '@wix/wixstores-client-core/dist/es/src/viewer-script/createViewerScript';
import {withErrorReporting} from '@wix/wixstores-client-core/dist/es/src/viewer-script/errorReporter';
import {IProductPageControllerConfig} from '../types/app-types';
import {getRuntimeStyleParams} from '../commons/styleParamsService';
import {wixCodeGetProduct} from '../services/getProduct';
import {ProductPageStore} from './ProductPageStore';
import {SPECS} from '../specs';

export function productPageController({
  context,
  config,
  setProps,
  reportError,
}: IControllerFactoryConfig): IWidgetController {
  let productPageStore: ProductPageStore;
  const {siteStore} = context;
  const isMobile = siteStore.isMobile();
  /* istanbul ignore next: todo: test */
  const isResponsive =
    config.style.styleParams?.booleans?.responsive ||
    (isMobile && siteStore.experiments.enabled(SPECS.RESPONSIVE_LAYOUT_FOR_CLASSIC_MOBILE));
  /* istanbul ignore next: todo: test */
  const {
    style: {styleParams},
    publicData,
    externalId = '',
  } = config;

  return {
    pageReady: () => {
      productPageStore = new ProductPageStore(
        getRuntimeStyleParams(styleParams, {isResponsive, isMobile}),
        publicData,
        setProps,
        siteStore,
        externalId,
        reportError
      );
      return productPageStore.setInitialState().catch(reportError);
    },
    updateConfig: (
      _$w: I$W,
      {style: {styleParams: newStyleParams}, publicData: newPublicData}: IProductPageControllerConfig
    ) => {
      productPageStore.updateState(getRuntimeStyleParams(newStyleParams, {isResponsive, isMobile}), newPublicData);
    },
    exports: () =>
      withErrorReporting(reportError)({
        getProduct: () => wixCodeGetProduct(siteStore, reportError),
        getCustomTextFieldsValues: (): string[] => {
          return productPageStore.userInputs.text;
        },
      }),
  };
}
